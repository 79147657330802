import { useIntl, FormattedMessage } from "react-intl";
import image1 from "../../assets/images/food_preservation_image1.png";
import image2 from "../../assets/images/food_preservation_image2.png";
import image3_1 from "../../assets/images/food_preservation_image3_1.png";
import image3_2 from "../../assets/images/food_preservation_image3_2.png";
import image4_1 from "../../assets/images/food_preservation_image4_1.png";
import image4_2 from "../../assets/images/food_preservation_image4_2.png";
import TitleContainer from "../../components/TitleContainer";
import ApplicationBenefits from "../../components/ApplicationBenefits";
import useIntlMessages from "../../hooks/useIntlMessages";
import SectionContainer from "../../components/SectionContainer";
import { Col, Container, Row } from "react-bootstrap";
import ResponsiveImage from "../../components/ResponsiveImage";
import SectionWrapper from "../../components/SectionWrapper";
import VimeoFrame from "../../components/VimeoFrame";
import TitleText from "../../components/TitleText";

const FoodPreservation = () => {
  const intl = useIntl();
  const benefitsList = useIntlMessages(
    "applications.foodPreservation.benefitsList"
  );

  return (
    <div>
      <TitleContainer>
        <FormattedMessage id="components.header.foodPreservation" />
      </TitleContainer>
      <SectionWrapper id="videoIntro">
        <Container>
          <VimeoFrame
            src={intl.formatMessage({
              id: "applications.foodPreservation.videoIntroUrl",
            })}
            title={intl.formatMessage({
              id: "components.header.foodPreservation",
            })}
          ></VimeoFrame>
        </Container>
      </SectionWrapper>
      <SectionContainer
        id="benefits"
        classNames="bg-dark"
        sectionStart={
          <TitleText
            header={intl.formatMessage({
              id: "applications.benefits.title",
            })}
            title={<ApplicationBenefits benefitsList={benefitsList} />}
          />
        }
      ></SectionContainer>
      <SectionContainer
        id="text1"
        sectionStart={
          <TitleText
            header={intl.formatMessage({
              id: "applications.foodPreservation.text1.header",
            })}
            title={
              <Row className="g-4">
                <Col xs={12}>
                  <ResponsiveImage src={image1} />
                </Col>
                <Col xs={12}>
                  <ResponsiveImage src={image2} />
                </Col>
              </Row>
            }
            description={intl.formatMessage({
              id: "applications.foodPreservation.text1.description",
            })}
          />
        }
      ></SectionContainer>
      <SectionContainer
        id="text2"
        header={intl.formatMessage({
          id: "applications.foodPreservation.text2.header",
        })}
        sectionStart={
          <ResponsiveImage src={image3_1} />
        }
        sectionEnd={
          <ResponsiveImage src={image3_2} />
        }
      ></SectionContainer>
      <SectionContainer
        id="text3"
        sectionStart={
          <ResponsiveImage src={image4_1} />
        }
        sectionEnd={
          <ResponsiveImage src={image4_2} />
        }
      ></SectionContainer>

    </div>
  );
};

export default FoodPreservation;
