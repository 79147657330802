import { Container } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import TitleContainer from "../components/TitleContainer";


const PrivacyPolicy = () => {
 
  return (
    <div>
      <TitleContainer>
        <FormattedMessage id="privacyPolicy.title" />
      </TitleContainer>
      <Container>
        <div className="text-section-container"><FormattedMessage id="privacyPolicy.content"
        values={{
          a: chunks => (
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.dz-worldwide.com"
            >
            {chunks}
          </a>),
          ar: chunks => (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.privacypolicies.com/blog/privacy-policy-template/#Use_Of_Cookies_Log_Files_And_Tracking"
                      >
                      {chunks}
                    </a>),
          strong: chunks => <strong>{chunks}</strong>,
          p: chunks => <p>{chunks}</p>,
          ul: chunks => <ul>{chunks}</ul>,
          li: chunks => <li>{chunks}</li>,
          h1: chunks => <h1>{chunks}</h1>,
          h2: chunks => <h2>{chunks}</h2>,
          h3: chunks => <h3>{chunks}</h3>,
        }} /></div>
      </Container>
    </div>
  );
};

export default PrivacyPolicy;